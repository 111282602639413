import React, { useState } from "react";
import axios from "axios";
import backendUrl from "../../BackendUrl/BackendUrl";
import { useCookies } from "react-cookie";
const LoginCandidat = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie] = useCookies(["uuid"]);

  const handleSubmit = (e) => {
    e.preventDefault();

    login();
  };

  // Retrieve existing candidats from localStorage

  // Find a candidat with matching email and password

  async function login() {
    axios
      .post(`${backendUrl}/Candidats/login`, {
        courriel: email,
        password: password,
        
      })
      .then((res) => {
        if (res.status === 201) {
          alert("Connexion réussie!");
          const id = res.data.user[0].id;
          setCookie("uuid", id);
          onLoginSuccess(id);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.error("Erreur:", err);
      });
  }

  return (
    <div className="accueil">
      <h2>Connexion Candidat</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Courriel:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Mot de passe:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Se connecter</button>
      </form>
    </div>
  );
};

export default LoginCandidat;
