import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Connection from "./Connection/Connection.jsx";
import Nav from "./Navigation/Nav";
import Welcome from "./Welcome/Welcome";
import JobOffersList from "./JobOffers/JobOffersList";
import EditJobOffer from "./JobOffers/EditJobOffer.js";
import CreateJobOffer from "./JobOffers/CreateJobOffer.js";
import ShowJobOffer from "./JobOffers/ShowJobOffer.js";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter future={{
    v7_relativeSplatPath: true,
  }}>
      
        <Routes>
          <Route path="/" element={<Nav />}>
            <Route index element={<Welcome />} />
          </Route>
          <Route path="/connexion" element={<Nav />}>
            <Route index element={<Connection />} />
          </Route>
          <Route path="/offres" element={<Nav />}>
            <Route index element={<JobOffersList />} />
          </Route>
          <Route path="/Jobs/:jobId" element={<ShowJobOffer />} />
          <Route path="/Jobs/new" element={<CreateJobOffer />} />
          <Route path="/Jobs/:jobId/update" element={<EditJobOffer />} />
          <Route
            path="*"
            element={
              <div id="not-found">
                <h1>404 Not Found</h1>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
