import React from "react";
import { useState, useEffect } from "react";
import "./Nav.css";
import LoginCandidat from "../SessionCandidat/LoginCandidat";
import RegisterCandidat from "../SessionCandidat/RegisterCandidat";
import LoginEnterprise from "../SessionEntreprise/LoginEntreprise";
import RegisterEnterprise from "../SessionEntreprise/RegisterEntreprise";
import JobOffersList from "../JobOffers/JobOffersList";
import backendUrl from "../../BackendUrl/BackendUrl";
import { Link, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";

function Nav() {
  const [isLogin, setIsLogin] = useState(true);
  const [userType, setUserType] = useState("candidat");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies(["uuid"]);

  useEffect(() => {
    if (cookies.uuid && cookies.uuid !== "undefined") {
        fetch(
            `${backendUrl}/utilisateurs/${cookies.uuid}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setUser(data.user);
                handleLoginSuccess(data.user.id);
                handleToggleUserType();
            })
            .catch((err) => {});
    }
}, []);
  console.log(users);

  const handleToggleUserType = () => {
    setUserType(userType === "candidat" ? "employeur" : "candidat");
    setIsLogin(true);
  };

  const handleLoginSuccess = (id) => {
    setIsAuthenticated(true);
    setUserId(id);
  };
  return (
    <div className="nav-container">
      <nav>
        <Link to="/">
          <h1>JobBoard</h1>
        </Link>
        <ul>
          <li>
            <Link to="/">
            <h1>Accueil</h1>
            </Link>
          </li>
          <li>
            <Link to="/offres">
            <h1>Offres</h1>
            </Link>
          </li>
          {isAuthenticated ? (
            <li>
              <Link to="/profile">
                <h1>Profile</h1>
              </Link>
            </li>
          ) : (
            <li>
              <Link to="/connexion">
                <h1>Connexion/inscription</h1>
              </Link>
            </li>
          )}
        </ul>
      </nav>
        <Outlet />
    </div>
  );
  /*return (
      <div className="container">
        {isAuthenticated ? (
          <JobOffersList userId={userId} userType={userType} />
        ) : userType === "candidat" ? (
          isLogin ? (
            <LoginCandidat onLoginSuccess={handleLoginSuccess} />
          ) : (
            <RegisterCandidat />
          )
        ) : userType === "employeur" ? (
          isLogin ? (
          <LoginEnterprise onLoginSuccess={handleLoginSuccess} />
          ) : (
            <RegisterEnterprise />
          )
        ) : null}
  
        {//Render buttons only if not authenticated }
        {!isAuthenticated && (
          <div className="accueil">
            <button
              onClick={() => setIsLogin((prev) => !prev)}
              className="toggle-button"
            >
              {isLogin ? "Créer un compte" : "Se connecter"}
            </button>
  
            <button onClick={handleToggleUserType} className="toggle-button">
              Interface {userType === "candidat" ? "Employeur" : "Candidat"}
            </button>
          </div>
        )}
      </div>
    );*/
}
export default Nav;
