import React, { useState, useEffect } from "react";
import axios from "axios";
import BackendUrl from "../../BackendUrl/BackendUrl";
import { useCookies } from "react-cookie";

const CreateJobOffer = () => {
  const [Nom, setNom] = useState("");
  const [Description, setDescription] = useState("");
  const [user, setUser] = useState(null);
  const [cookies] = useCookies(["uuid"]);

  useEffect(() => {
    if (cookies.uuid && cookies.uuid !== "undefined") {
      fetch(`${BackendUrl}/utilisateurs/${cookies.uuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setUser(data.user);
        })
        .catch((err) => {});
    }
  }, [cookies.uuid]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle job creation logic here
    if (user && Object.keys(user).length > 0) {
      createJobs();
    }
  };

  async function createJobs() {
    axios
      .post(`${BackendUrl}/Jobs/new`, {
        titre: Nom,
        id_employeur: user[0].id,
        description: Description,
      })
      .then((res) => {
        if (res.status === 201) {
          alert("Création de l'offre réussie !");
          window.location.href = "/offres";
        }
      })
      .catch((err) => {
        console.error("Erreur lors de la création de l'offre :", err);
      });
  }

  return (
    <div className="container">
      <h2>Créer une Offre d'Emploi</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Titre:</label>
          <input
            type="text"
            value={Nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            type="text"
            value={Description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Créer l'offre</button>
      </form>
    </div>
  );
};

export default CreateJobOffer;
