import React, { useState, useEffect } from "react";
import BackendUrl from "../../BackendUrl/BackendUrl";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";


const JobOffersList = ({ userType, userId }) => {
  const [jobOffers, setJobOffers] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["uuid"]);
  const [user, setUser] = useState({});
  const [filteredJobOffers, setFilterdJobOffers] = useState([]);

  useEffect(() => {
    if (cookies.uuid && cookies.uuid !== "undefined") {
      fetch(`${BackendUrl}/utilisateurs/${cookies.uuid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUser(data.user);
        })
        .catch((err) => {});
    }
  }, [cookies.uuid]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      fetchJobOffers();
    }
  }, [user]);
  const fetchJobOffers = async () => {
    try {
      const url =
        user[0]?.role === "employeur"
          ? `${BackendUrl}/Employeurs/${user[0].id}/offres`
          : `${BackendUrl}/Jobs`;

      const response = await fetch(url);
      const data = await response.json();
      if(data.job !== undefined){
        setJobOffers(data.job);
      }
    
    } catch (err) {
      console.error("Erreur lors de la récupération des offres :", err);
    }
  };
//test
  useEffect(() => {
    if (jobOffers && jobOffers.length > 0 && user[0]?.role === "candidat") {
      setFilterdJobOffers(jobOffers.filter((job) => job.visible !== 0));
    }else if(jobOffers && jobOffers.length > 0 && user[0]?.role === "employeur"){
      setFilterdJobOffers(jobOffers);
    }
  }, [jobOffers]);
  const handleDelete = async (jobId) => {
    try {
      const response = await fetch(`${BackendUrl}/Jobs/${jobId}/delete`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Erreur lors de la suppression de l'offre d'emploi");
      }
      // Mettre à jour la liste des offres d'emploi après suppression
      setJobOffers((prevOffers) =>
        prevOffers.filter((offer) => offer.id !== jobId)
      );
    } catch (err) {
      console.error(err);
    }
  };
 

  return (
    <div className="job-offers-container">
      <h2>Offres d'Emploi</h2>
      <h4>
        {user[0]?.role === "employeur" && (
          <Link to="/Jobs/new">Créer une offre d'emploi</Link>
        )}
      </h4>
      
      <ul>
        {filteredJobOffers && filteredJobOffers.length > 0 ? (
          filteredJobOffers.map((job) => (
            <li key={job.id}>
              <h3>
                <Link to={`/Jobs/${job.id}`}>{job.titre} </Link>
              </h3>
              {user[0]?.role === "employeur" && (
                <div>
                  <Link to={`/Jobs/${job.id}/update`}>Modification</Link>
                  <div className="supprimer">
                    <button onClick={() => handleDelete(job.id)}>
                      Supprimer
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))
        ) : (
          <p>Aucune offre d'emploi disponible</p>
        )}
      </ul>
    </div>
  );
};

export default JobOffersList;
