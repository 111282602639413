import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import backendUrl from "../../BackendUrl/BackendUrl";
import "./Welcome.css";

function Welcome() {
    const [cookies, setCookie, removeCookie] = useCookies(["uuid"]);
useEffect(() => {
    if(cookies.uuid && cookies.uuid !== "undefined") {
        fetch( `${backendUrl}/utilisateurs/${cookies.uuid}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
        })
        .catch((err) => {});
    }
}   , []);
    
return(
    <div className="welcome-container">
        <h1>welcome</h1>
    </div>
)

}

export default Welcome;