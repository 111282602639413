import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import backendUrl from "../../BackendUrl/BackendUrl";

const ShowJobOffer = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the job offer details based on jobId
    axios
      .get(`${backendUrl}/Jobs/${jobId}`)
      .then((response) => {
        setJob(response.data.job);
        setLoading(false);
      })
      .catch((error) => {
        setError("Erreur lors du chargement de l'offre d'emploi");
        setLoading(false);
      });
  }, [jobId]);

  if (loading) {
    return <p>Chargement de l'offre d'emploi...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="job-details-container">
      {job ? (
        <div>
          <h2>{job.titre}</h2>
          <p>{job.description}</p>
          <p>
            Contact : {job.user_nom} {job.user_prenom}
          </p>
          <p>Email : {job.user_email}</p>
          <p>
            Téléphone : {job.user_telephone ? job.user_telephone : "Non fourni"}
          </p>
          <p>Adresse :<a href={"https://maps.google.com/?q="+job.user_adresse }>{job.user_adresse ? job.user_adresse : "Non fournie"}</a> </p>
        </div>
      ) : (
        <p>Cette offre d'emploi n'existe pas.</p>
      )}
    </div>
  );
};

export default ShowJobOffer;
