import React from "react";
import { useState, useEffect } from "react";
import LoginCandidat from "../SessionCandidat/LoginCandidat";
import RegisterCandidat from "../SessionCandidat/RegisterCandidat";
import LoginEntreprise from "../SessionEntreprise/LoginEntreprise";
import RegisterEntreprise from "../SessionEntreprise/RegisterEntreprise";
import JobOffersList from "../JobOffers/JobOffersList";

import "./Connection.css";

function Connection() {
  const [isLogin, setIsLogin] = useState(false);
  const [userType, setUserType] = useState("candidat");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleToggleUserType = () => {
    setUserType(userType === "candidat" ? "employeur" : "candidat");
    setIsLogin(true);
  };

  const handleLoginSuccess = (id) => {
    setIsAuthenticated(true);
    setUserId(id);
  };
  return (
    <div className="connexion">
      {isAuthenticated ? (
        <JobOffersList userType={userType} userId={userId} />
      ) : userType === "candidat" ? (
        isLogin ? (
          <LoginCandidat onLoginSuccess={handleLoginSuccess} />
        ) : (
          <RegisterCandidat />
        )
      ) : userType === "employeur" ? (
        isLogin ? (
          <LoginEntreprise onLoginSuccess={handleLoginSuccess} />
        ) : (
          <RegisterEntreprise />
        )
      ) : null}
      <div className="center-buttons">
        <button
          onClick={() => setIsLogin((prev) => !prev)}
          className="toggle-button"
        >
          {isLogin ? "Créer un compte" : "Se connecter"}
        </button>
        <br />
        <button onClick={handleToggleUserType} className="toggle-button">
          Interface {userType === "candidat" ? "Employeur" : "Candidat"}
        </button>
      </div>
    </div>
  );
}
export default Connection;
