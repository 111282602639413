import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import backendUrl from "../../BackendUrl/BackendUrl";

const EditJobOffer = () => {
  const { jobId } = useParams();
  const [Nom, setNom] = useState("");
  const [Description, setDescription] = useState("");
  const [visible, setVisible] = useState(1);

  useEffect(() => {
    // Fetch the job details by ID to pre-fill the form
    axios
      .get(`${backendUrl}/Jobs/${jobId}`)
      .then((res) => {
        const job = res.data.job;
        setNom(job.titre);
        setDescription(job.description);
        setVisible(job.visible);
      })
      .catch((err) => {
        console.error("Erreur:", err);
      });
  }, [jobId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle job creation logic here
    EditJob();
  };

  async function EditJob() {
    axios
      .patch(`${backendUrl}/Jobs/${jobId}/update`, {
        titre: Nom,
        description: Description,
        visible: visible,
      })
      .then((res) => {
        if (res.status === 201) {
          alert("Modification réussite!");
          window.location.href = "/offres";
        }
      })
      .catch((err) => {
        console.error("Erreur:", err);
      });
  }

  return (
    <div className="container">
      <h2>Modifier une Offre d'Emploi</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Titre:</label>
          <input
            type="text"
            value={Nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            type="text"
            value={Description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label for="flip-1">Activation de l'emploi</label>
          <select name="flip-1" placeHolder="Visibilite" id="flip-1" onChange={(e) => setVisible(e.target.value)}>
            <option value="1">Visible</option>
            <option value="0">Non visible</option> 
          </select>
        
        </div>
        <button type="submit">Modifier l'offre</button>
        {/**/}
      </form>
    </div>
  );
};

export default EditJobOffer;
